import React, { useEffect, useRef, useState } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  COLOR_GRAY_TEXT,
  COLOR_PRIMARY,
} from "../../../../../../../constants/Colors";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  eraseRoom,
  eraseRoomChat,
  getRoomAppChat,
  getRoomChat,
  sendAppChat,
  sendRoomChat,
} from "../../../../../../../redux/actions/auth";
import { ADMIN } from "../../../../../../../constants/UserType";
import { errorToast } from "../../../../../../../services/toastService";
import ChatWindow from "../../../../../../../components/organisms/chatWindow/ChatWindow";
import {
  setLoadingSpinerOff,
  setLoadingSpinerOn,
} from "../../../../../../../redux/actions/app";
import { uploadToCloudinary } from "../../../../../../../utils/helpers";

const Divider = styled.hr`
  background-color: ${COLOR_PRIMARY};
  height: 1px;
  margin: 0px;
  width: 90%;
  opacity: 0.3;
`;

const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  /* display: inline-block; */
  padding: 0px 10px;
`;

function ChatDriver({
  id_tripDetail,
  tripId,
  fullSize = false,
  title = "Chat",
}) {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.authApp);
  const { appChat } = useSelector((state) => state.chatReducer);
  const uploadData = async (document) => {
    const file = await uploadToCloudinary({
      file: document,
      folder: tripId ? `chatDriver/${tripId || id_tripDetail}` : "chatDriver",
    });
    if (file) {
      return file?.secure_url;
    } else {
      errorToast("Por favor intente nuevamente");
    }
  };

  useEffect(() => {
    dispatch(getRoomAppChat({ token, room: id_tripDetail }));
    return () => dispatch(eraseRoomChat());
  }, [id_tripDetail]);

  return (
    <Container className={fullSize ? " h-100" : "py-4"}>
      <Row
        className={
          fullSize ? "justify-content-center h-100" : "justify-content-center"
        }
      >
        <Col md={fullSize ? 12 : 10} className="p-0 px-md-3 h-100">
          <ChatWindow
            title={title}
            data={appChat}
            roleSent={user.role_id.name}
            className={fullSize ? "h-100" : ""}
            size={fullSize ? "100%" : undefined}
            handleSubmitAPI={async ({
              values,
              resetForm,
              setImage,
              image,
              id_receptor,
            }) => {
              try {
                dispatch(setLoadingSpinerOn());

                let document = "";
                if (values?.document) {
                  document = await uploadData(image);
                }

                (values.comment || document) &&
                  dispatch(
                    sendAppChat({
                      token,
                      msg: values.comment,
                      document: document,
                      room: id_tripDetail,
                    })
                  );

                resetForm();
                setImage({});
              } catch (error) {
                resetForm();
                setImage({});
                errorToast(error?.message + ". Por favor intente nuevamente");
              } finally {
                dispatch(setLoadingSpinerOff());
              }
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default ChatDriver;
