import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  eraseRoom,
  getRoomChat,
  sendRoomChat,
} from "../../../../../../../redux/actions/auth";
import { errorToast } from "../../../../../../../services/toastService";
import ChatWindow from "../../../../../../../components/organisms/chatWindow/ChatWindow";
import { uploadToCloudinary } from "../../../../../../../utils/helpers";
import {
  setLoadingSpinerOff,
  setLoadingSpinerOn,
} from "../../../../../../../redux/actions/app";

function ChatByTrip({ id_trip, id_tripDetail, tripId }) {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.authApp);
  const { chatRoom } = useSelector((state) => state.chatReducer);
  const uploadData = async (document) => {
    const file = await uploadToCloudinary({
      file: document,
      folder: tripId ? `chatBytrip/${tripId}` : "chatBytrip",
    });
    if (file) {
      return file?.secure_url;
    } else {
      errorToast("Por favor intente nuevamente");
    }
  };

  useEffect(() => {
    dispatch(getRoomChat({ token, room: id_tripDetail }));
    return () => dispatch(eraseRoom());
  }, [id_tripDetail]);

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md={10}>
          <ChatWindow
            title={"Chat "}
            data={chatRoom}
            roleSent={user.role_id.name}
            /* className={"h-100"}
            size={"100%"} */
            handleSubmitAPI={async ({
              values,
              resetForm,
              setImage,
              image,
              id_receptor,
            }) => {
              try {
                dispatch(setLoadingSpinerOn());
                let document = "";
                if (values?.document) {
                  document = await uploadData(image);
                }

                (values.comment || document) &&
                  dispatch(
                    sendRoomChat({
                      token,
                      msg: values.comment,
                      document: document,
                      to: id_tripDetail,
                    })
                  );

                resetForm();
                setImage({});
              } catch (error) {
                resetForm();
                setImage({});
                errorToast(error?.message + ". Por favor intente nuevamente");
              } finally {
                dispatch(setLoadingSpinerOff());
              }
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default ChatByTrip;
