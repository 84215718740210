import React, { useEffect, useState } from "react";
import { Modal, Col, Row, Image, Stack, Badge, Button } from "react-bootstrap";
import DefaultAvatar from "../../../assets/img/default-avatar.png";
import styled from "styled-components";
import { COLOR_GRAY_TEXT, COLOR_PRIMARY } from "../../../constants/Colors";
import LogSelectLocation from "../../atoms/LogSelectLocation";
import { useFormik } from "formik";
import * as Yup from "yup";
import ChatDriver from "../../../pages/Dashboard/views/MyTravels/InActions/TripDetails/ChatDriver/ChatDriver";
import { getLocationsInAction } from "../../../api/modules";
import { getDriverByLocation } from "../../../api/modules/driver";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineClose,
} from "react-icons/ai";
import { useSelector } from "react-redux";

const ModalLogStyled = styled(Modal)`
  opacity: 1;

  .modal-content {
    border-radius: 15px !important;
    padding: 20px;
    height: 90vh;
    max-height: 90vh;
  }
  .modal-body {
    overflow: hidden;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const SearchContainer = styled(Col)`
  height: 100%;
  /* @media (max-width: 991px) {
    height: 300px;
  } */
`;

const ChatContainer = styled(Col)`
  height: 100%;
  /* @media (max-width: 991px) {
    height: 400px;
  } */
`;

const ColStyled = styled(Col)`
  height: 85%;
  overflow-y: auto;
  /* @media (max-width: 991px) {
    height: 190px;
  } */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #d62929;
  }
`;

const Divider = styled.hr`
  background-color: ${COLOR_PRIMARY};
  height: 1px;
  margin: 0px;
  width: 90%;
  opacity: 0.3;
`;

const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  /* display: inline-block; */
  padding: 0px 10px;
`;

const ProfileSchema = Yup.object()
  .shape({
    origin: Yup.string().nullable(),
    destiny: Yup.string().nullable(),
  })
  .test(
    "at-least-one-required",
    "Origen o destino es necesario",
    function (values) {
      return values.origin || values.destiny;
    }
  );

const ModalChatDriver = ({
  isShow,
  handleClose,
  name,
  lastName,
  srcAvatar,
  phone,
  truckType,
  chassis,
  coupled,
}) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 991);
  const [showSearchContainer, setShowSearchContainer] = useState(true);
  const [showChatContainer, setShowChatContainer] = useState(false);

  const [selectUser, setSelectUser] = useState("");
  const [id_tripDetail, setId_tripDetail] = useState(null);
  const [query, setQuery] = useState({
    originLocation: "",
    destinyLocation: "",
  });
  const { unReadChatApp } = useSelector((state) => state.chatReducer);
  const [originLocation, setOriginLocation] = useState([]);
  const [destinyLocation, setDestinyLocation] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const getLocations = async () => {
    const { origin_sites, destiny_sites } = await getLocationsInAction();
    if (origin_sites && destiny_sites) {
      setOriginLocation(() =>
        origin_sites.map((ele) => {
          return {
            label: ele?.province?.name + " - " + ele?.locality?.name,
            value: ele._id,
          };
        })
      );
      setDestinyLocation(() =>
        destiny_sites.map((ele) => {
          return {
            label: ele?.province?.name + " - " + ele?.locality?.name,
            value: ele._id,
          };
        })
      );
    }
  };

  const getDrivers = async () => {
    const { drivers } = await getDriverByLocation(query);
    if (drivers) setDrivers(() => drivers);
  };

  const getOrder = () => {
    const data = drivers.filter((ele) => ele._id === id_tripDetail)[0];
    return data.id_trip?.id_trip + data?.order || id_tripDetail;
  };

  const getTitle = () => {
    const data = drivers.filter((ele) => ele._id === id_tripDetail)[0];
    if (data) {
      const { id_trip, destiny_site, origin_site, order } = data;
      return (
        <Row>
          <Col>
            <div className="d-flex align-items-center">
              <AiOutlineArrowLeft className="mr-2" onClick={handleGoBack} />{" "}
              <div>
                <div>
                  <small className="text-capitalize">
                    {origin_site?.site_name || "N/D"}
                    <AiOutlineArrowRight className="mb-1 ml-2" />{" "}
                    {destiny_site?.site_name || "N/D"}
                  </small>
                </div>
                <div>
                  <small>
                    ID: {id_trip?.id_trip || "N/D"}
                    {order || "N/D"}
                  </small>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      );
    }
  };

  useEffect(() => {
    if (drivers.length > 0) {
      setSelectUser(drivers[0].id_driver?._id);
      setId_tripDetail(drivers[0]._id);
    } else {
      setSelectUser("");
      setId_tripDetail(null);
    }
  }, [drivers]);

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    getDrivers();
  }, [query]);

  const { values, errors, handleBlur, setFieldValue, resetForm } = useFormik({
    initialValues: {
      origin: "",
    },
    onSubmit: async (values) => {
      console.log("VALUES", values);
    },
    validationSchema: ProfileSchema,
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleGoBack = () => {
    setShowSearchContainer(true);
    setShowChatContainer(false);
    setSelectUser("");
    setId_tripDetail(null);
  };

  return (
    <ModalLogStyled
      show={isShow}
      onHide={handleClose}
      className="modal-exit"
      size={"xl"}
      centered
      enforceFocus={false}
    >
      {isMobileView ? (
        <AiOutlineClose
          onClick={handleClose}
          size={22}
          style={{
            zIndex: 1100,
            position: "absolute",
            top: 3,
            right: 6,
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          }}
        />
      ) : null}

      <ModalLogStyled.Body>
        <Row
          className="w-100 justify-content-center /* m-0 */ "
          style={{ position: "absolute", top: 0, bottom: 0, left: 0 }}
        >
          {isMobileView ? (
            showSearchContainer && (
              <SearchContainer xs={12} lg={4} className="pr-md-0 mr-0">
                <Stack className="mb-3 gap-4">
                  <LogSelectLocation
                    placeholder="Origen"
                    className="mt-1"
                    name="origin"
                    cleanable
                    searchable={originLocation.length === 0 ? false : true}
                    items={originLocation}
                    onChange={(value) => {
                      setQuery((state) => {
                        return { ...state, originLocation: value };
                      });
                    }}
                    onBlur={handleBlur.origin}
                    errors={errors}
                    value={query.originLocation}
                  />
                  <LogSelectLocation
                    placeholder="Destino"
                    className="mt-1"
                    name="destiny"
                    cleanable
                    searchable={originLocation.length === 0 ? false : true}
                    items={destinyLocation}
                    onChange={(value) => {
                      setQuery((state) => {
                        return { ...state, destinyLocation: value };
                      });
                    }}
                    onBlur={handleBlur.destiny}
                    errors={errors}
                    value={query.destinyLocation}
                  />
                </Stack>
                <ColStyled className="mb-2">
                  {drivers.map(
                    (
                      {
                        _id,
                        id_driver,
                        id_trip,
                        order,
                        origin_site,
                        destiny_site,
                      },
                      index
                    ) => (
                      <React.Fragment key={index}>
                        <Row
                          className={`p-2  + ${
                            selectUser === id_driver._id
                              ? "bg-light shadow-4dp"
                              : ""
                          }`}
                          onClick={() => {
                            setSelectUser(id_driver._id);
                            setId_tripDetail(_id);
                            setShowSearchContainer(false);
                            setShowChatContainer(true);
                          }}
                        >
                          <Col
                            xs={3}
                            style={id_driver.onLine ? null : { opacity: 0.5 }}
                          >
                            <Image
                              roundedCircle
                              src={id_driver?.image || DefaultAvatar}
                              alt="avatar 1"
                              className="w-100 mh-100"
                              //style={{ width: "45px", maxHeight: "100%" }}
                            />
                          </Col>
                          <Col
                            xs={7}
                            style={id_driver.onLine ? null : { opacity: 0.5 }}
                          >
                            <Row>
                              <small className="text-capitalize">
                                {id_driver.name + " " + id_driver.last_name}
                              </small>
                            </Row>
                            <Row>
                              <small className="">
                                {"ID Viaje " +
                                  (id_trip?.id_trip
                                    ? id_trip?.id_trip
                                    : "N/D") +
                                  (order ? order : "N/D")}
                              </small>
                            </Row>
                            <Row>
                              <small className="text-capitalize">
                                {origin_site.site_name || "N/D"}{" "}
                                <AiOutlineArrowRight className="mb-1" />{" "}
                                {destiny_site.site_name || "N/D"}
                              </small>
                            </Row>
                          </Col>
                          <Col md={2} className={"mx-auto"}>
                            {unReadChatApp?.filter((ele) => ele._id === _id)
                              .length > 0 ? (
                              <BadgeLog as="h6">
                                {
                                  unReadChatApp?.filter(
                                    (ele) => ele._id === _id
                                  )[0].size
                                }
                              </BadgeLog>
                            ) : null}
                          </Col>
                        </Row>
                        <Divider />
                      </React.Fragment>
                    )
                  )}
                </ColStyled>
              </SearchContainer>
            )
          ) : (
            <SearchContainer xs={12} lg={4} className="pr-md-0 mr-0">
              <Stack className="mb-3 gap-4">
                <LogSelectLocation
                  placeholder="Origen"
                  className="mt-1"
                  name="origin"
                  cleanable
                  searchable={originLocation.length === 0 ? false : true}
                  items={originLocation}
                  onChange={(value) => {
                    setQuery((state) => {
                      return { ...state, originLocation: value };
                    });
                  }}
                  onBlur={handleBlur.origin}
                  errors={errors}
                  value={query.originLocation}
                />
                <LogSelectLocation
                  placeholder="Destino"
                  className="mt-1"
                  name="destiny"
                  cleanable
                  searchable={originLocation.length === 0 ? false : true}
                  items={destinyLocation}
                  onChange={(value) => {
                    setQuery((state) => {
                      return { ...state, destinyLocation: value };
                    });
                  }}
                  onBlur={handleBlur.destiny}
                  errors={errors}
                  value={query.destinyLocation}
                />
              </Stack>
              <ColStyled className="mb-2">
                {drivers.map(
                  (
                    {
                      _id,
                      id_driver,
                      id_trip,
                      order,
                      origin_site,
                      destiny_site,
                    },
                    index
                  ) => (
                    <React.Fragment key={index}>
                      <Row
                        className={`p-2  + ${
                          selectUser === id_driver._id
                            ? "bg-light shadow-4dp"
                            : ""
                        }`}
                        onClick={() => {
                          setSelectUser(id_driver._id);
                          setId_tripDetail(_id);
                          setShowSearchContainer(false);
                          setShowChatContainer(true);
                        }}
                      >
                        <Col
                          xs={3}
                          style={id_driver.onLine ? null : { opacity: 0.5 }}
                        >
                          <Image
                            roundedCircle
                            src={id_driver?.image || DefaultAvatar}
                            alt="avatar 1"
                            className="w-100 mh-100"
                            //style={{ width: "45px", maxHeight: "100%" }}
                          />
                        </Col>
                        <Col
                          xs={7}
                          style={id_driver.onLine ? null : { opacity: 0.5 }}
                        >
                          <Row>
                            <small className="text-capitalize">
                              {id_driver.name + " " + id_driver.last_name}
                            </small>
                          </Row>
                          <Row>
                            <small className="">
                              {"ID Viaje " +
                                (id_trip?.id_trip ? id_trip?.id_trip : "N/D") +
                                (order ? order : "N/D")}
                            </small>
                          </Row>
                          <Row>
                            <small className="text-capitalize">
                              {origin_site.site_name || "N/D"}{" "}
                              <AiOutlineArrowRight className="mb-1" />{" "}
                              {destiny_site.site_name || "N/D"}
                            </small>
                          </Row>
                        </Col>
                        <Col md={2} className={"mx-auto"}>
                          {unReadChatApp?.filter((ele) => ele._id === _id)
                            .length > 0 ? (
                            <BadgeLog as="h6">
                              {
                                unReadChatApp?.filter(
                                  (ele) => ele._id === _id
                                )[0].size
                              }
                            </BadgeLog>
                          ) : null}
                        </Col>
                      </Row>
                      <Divider />
                    </React.Fragment>
                  )
                )}
              </ColStyled>
            </SearchContainer>
          )}
          {isMobileView ? (
            showChatContainer && (
              <ChatContainer xs={12} lg={8}>
                <ChatDriver
                  id_tripDetail={id_tripDetail}
                  fullSize
                  onBack={handleGoBack}
                  title={getTitle()}
                  tripId={getOrder()}
                />
              </ChatContainer>
            )
          ) : (
            <ChatContainer xs={12} lg={8}>
              <ChatDriver
                id_tripDetail={id_tripDetail}
                fullSize
                tripId={getOrder()}
              />
            </ChatContainer>
          )}
        </Row>
      </ModalLogStyled.Body>
    </ModalLogStyled>
  );
};

export default ModalChatDriver;
