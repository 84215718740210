/* eslint-disable no-unused-vars */
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  addDriver,
  editDriver,
  getDriver,
  deleteLogicDriver,
} from "../../../../../api/modules/driver";


// react-bootstrap components
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Badge,
} from "react-bootstrap";
import { errorToast, successToast } from "../../../../../services/toastService";
import LogInput from "../../../../../components/atoms/LogInput";
import LogButton from "../../../../../components/atoms/LogButton";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import ContentHeader from "../../../components/molecules/ContentHeader";
import ModalQuestion from "../../../../../components/organisms/modal-question/ModalQuestion";
import ModalInfo from "../../../../../components/organisms/modal-info/ModalInfo";
import LogDateInput from "../../../../../components/atoms/LogDateInput";

import DropZone from "../../../../../components/organisms/dropzone/DropZone";
import { useSelector, useDispatch } from "react-redux";
import {
  setLoadingSpinerOff,
  setLoadingSpinerOn,
} from "../../../../../redux/actions/app";
import LogSelect from "../../../../../components/atoms/LogSelect";
import LogDatePicker from "../../../../../components/atoms/LogDatePicker";
import { COLOR_PRIMARY, COLOR_RED } from "../../../../../constants/Colors";
import LogInputPhone from "../../../../../components/atoms/LogInputPhone";
import { uploadToCloudinary } from "../../../../../utils/helpers";

const BtnInvisible = styled(Button)`
  background: none;
  border: none;
  &:hover {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:active {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:focus {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
`;
const StyledLogButton = styled(LogButton)`
  width: 100%;
  max-width: 200px;
`;
const RowDeleteStyled = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  right: 15px;
  justify-content: flex-end;
  position: absolute;
  top: 5px;
`;
const Divider = styled.hr`
  /* background-color: ${COLOR_PRIMARY}; */
  height: 1px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 98%;
  opacity: 1;
`;
const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
`;

const BadgeLogRed = styled(Badge)`
  background-color: ${COLOR_RED};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
`;

const CardLogBootstrapStyled = styled(Card)`
  padding: 10px;
  border-radius: 15px;
`;
const DriverSchema = Yup.object().shape({
  name: Yup.string().required("Nombre es requerido"),
  last_name: Yup.string().required("Apellido es requerido"),
  cuit: Yup.number()
    .typeError("Solo numeros, sin guiones")
    .required("Ingrese un cuit valido"),
  date_of_birth: Yup.date()
    .nullable()
    .required("Fecha de nacimiento es obligatoria")
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      "Debes tener al menos 18 años"
    )
    .min(
      new Date(new Date().setFullYear(new Date().getFullYear() - 65)),
      "No puedes tener más de 65 años"
    ),

  email: Yup.string().email().required("Ingrese un email valido"),
  license_one: Yup.string().required("Licencia es requerido"),
  license_two: Yup.string().required("Licencia es requerido"),
  phone: Yup.string()
    .matches(/^\+\d{12}$/, "Ej. +543811234456")
    .required("Teléfono obligatorio"),
});

function MyDriver() {
  const [modalExit, setModalExit] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const history = useHistory();
  const params = useParams();
  const id_tomador = useSelector((state) => state.authApp.user._id);
  const dispatch = useDispatch();
  const [entity, setEntity] = useState({});
  const [licenseOnePhoto, setLicenseOnePhoto] = useState(null);
  const [licenseTwoPhoto, setLicenseTwoPhoto] = useState(null);
  const [lintiOnePhoto, setLintiOnePhoto] = useState(null);
  const [lintiTwoPhoto, setLintiTwoPhoto] = useState(null);
  const [showLicenseOnePhoto, setShowLicenseOnePhoto] = useState(true);
  const [showLicenseTwoPhoto, setShowLicenseTwoPhoto] = useState(true);
  const [showLintiOnePhoto, setShowLintiOnePhoto] = useState(true);
  const [showLintiTwoPhoto, setShowLintiTwoPhoto] = useState(true);
  const [fileErrors, setTileErrors] = useState({
    inFrontError: false,
    backError: false,
    lintiInFrontError: false,
    lintiBackError: false,
  });
  const [isEditing, setIsEditing] = useState(true);
  const [resetDropZone, setResetDropZone] = useState(false);
  const resetEditing = () => {
    setIsEditing(false);
    setResetDropZone(true);
    setShowLicenseOnePhoto(true);
    setShowLicenseTwoPhoto(true);
    setShowLintiOnePhoto(true);
    setShowLintiTwoPhoto(true);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const { id } = params;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchData(id);
  }, []);
  useEffect(() => {
    if (entity?._id) setIsEditing(false);
  }, [entity]);

  const fetchData = async (id) => {
    const response = await getDriver(id);
    const { driver } = response;
    if (driver) {
      setEntity(driver);
    } else {
      setEntity({});
    }
  };

  const uploadData = async (license, email) => {
    const file = await uploadToCloudinary({
      file: license?.file,
      folder: email ? `driver/${email}` : "driver",
    });
    if (file) {
      return file?.secure_url;
    } else {
      errorToast("Por favor intente nuevamente");
    }
  };

  const handleDeleteItem = async (id, active) => {
    const body = {
      is_active: !active,
    };
    const resp = await deleteLogicDriver(id, body);
    const { msg, driver } = resp;
    if (!msg) {
      driver.is_active
        ? successToast(`Su chofer ahora se encuentra activo`)
        : successToast(`Su chofer pasó a estado inactivo`);
    } else {
      errorToast(msg);
    }
    history.push("/app/my-drivers");
  };

  const handleSuccessModal = async (response) => {
    if (response) history.push("/app/my-drivers");
  };
  const handleCloseExitModal = async (response) => {
    if (response) {
      await handleDeleteItem(entity._id, entity.is_active);
      setModalExit(false);
    } else {
      setModalExit(false);
    }
  };

  const RowDelete = () => {
    return entity?._id ? (
      <RowDeleteStyled>
        <OverlayTrigger
          key="delete"
          overlay={
            <Tooltip id={`delete`}>
              {entity.is_active ? "Desactivar" : "Activar"}
            </Tooltip>
          }
        >
          <BtnInvisible
            onClick={(event) => {
              event.stopPropagation();
              setModalExit(true);
            }}
          >
            {entity.is_active ? (
              <BadgeLog className="cursor-pointer" as="h5">
                Activo
              </BadgeLog>
            ) : (
              <BadgeLogRed className="cursor-pointer" as="h5">
                Inactivo
              </BadgeLogRed>
            )}
          </BtnInvisible>
        </OverlayTrigger>
      </RowDeleteStyled>
    ) : (
      ""
    );
  };

  const handleSubmitAPI = async (values, resetForm) => {
    try {
      let body = null;
      scrollToTop();
      let { is_danger, ...rest } = values;
      is_danger = is_danger === "dangerous" ? true : false;

      body = {
        is_danger,
        ...rest,
      };

      if (!showLicenseOnePhoto) body = { ...body, license_one: "" };
      if (!showLicenseTwoPhoto) body = { ...body, license_two: "" };

      let response;
      dispatch(setLoadingSpinerOn());
      if (licenseOnePhoto?.file) {
        let uploadLicense = await uploadData(licenseOnePhoto, body?.email);
        if (uploadLicense) {
          body = { ...body, license_one: uploadLicense };
        } else {
          return;
        }
      }
      if (licenseTwoPhoto?.file) {
        let uploadLicense = await uploadData(licenseTwoPhoto, body?.email);
        if (uploadLicense) {
          body = { ...body, license_two: uploadLicense };
        } else {
          return;
        }
      }
      if (lintiOnePhoto?.file) {
        let uploadLicense = await uploadData(lintiOnePhoto, body?.email);
        if (uploadLicense) {
          body = { ...body, linti_one: uploadLicense };
        } else {
          return;
        }
      }
      if (lintiTwoPhoto?.file) {
        let uploadLicense = await uploadData(lintiTwoPhoto, body?.email);
        if (uploadLicense) {
          body = { ...body, linti_two: uploadLicense };
        } else {
          return;
        }
      }
      if (entity?._id) {
        response = await editDriver(entity._id, body);
        if (!response.msg) {
          successToast("Chofer editado con éxito");
          history.push("/app/my-drivers");
        } else {
          errorToast(response.msg);
        }
        return;
      } else {
        response = await addDriver(body);
        if (response?.msg) {
          errorToast(response?.msg);
          history.push("/app/my-drivers");
        } else {
          setSuccessModal(true);
        }
      }
    } catch (error) {
      console.error("Submit MyDriver", error);
    } finally {
      dispatch(setLoadingSpinerOff());
      resetForm();
      resetEditing();
    }
  };

  return (
    <>
      <Container fluid>
        <Formik
          enableReinitialize
          initialValues={{
            name: entity?.name || "",
            last_name: entity?.last_name || "",
            cuit: entity?.cuit || "",
            is_active: entity?.is_active ? true : false,
            date_of_birth: entity?.date_of_birth || null,
            email: entity?.email || "",
            /* password: "", */
            id_tomador: id_tomador,
            license_one: entity?.license_one || "",
            license_two: entity?.license_two || "",
            phone: entity?.phone || "",
            is_danger: entity?.is_danger ? "dangerous" : "general" || "general",
            license_date: entity?.license_date || null,
            linti_one: entity?.linti_one || "",
            linti_two: entity?.linti_two || "",
            lnh_date: entity?.lnh_date || null,
          }}
          validationSchema={DriverSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmitAPI(values, resetForm);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            dirty,
            isValid,
            setFieldValue,
          }) => (
            <Row>
              <ContentHeader
                className={`ml-3`}
                title={entity?._id ? `Editar chofer ` : `Agregar chofer`}
              />
              <Col
                md="12"
                onClick={() => {
                  if (entity?._id && !isEditing) {
                    successToast(
                      "Para realizar cambios, toca el botón 'Editar' que está al final del formulario."
                    );
                  }
                }}
              >
                <CardLogBootstrapStyled className="shadow-4dp pt-3">
                  <CardLogBootstrapStyled.Body>
                    <RowDelete />
                    <Row className="mt-2">
                      <Col md="4">
                        <Form.Group>
                          <label className="text-primary font-weight-bold">
                            Ingresá el nombre de tu chofer
                          </label>
                          <LogInput
                            placeholder="Ingresá el nombre de tu chofer"
                            type="text"
                            name="name"
                            readOnly={!isEditing}
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue(
                                "name",
                                value.toLowerCase().charAt(0).toUpperCase() +
                                  value?.slice(1)
                              );
                            }}
                            errors={errors}
                            value={values.name}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group>
                          <label className="text-primary font-weight-bold">
                            Ingresá el apellido de tu chofer
                          </label>
                          <LogInput
                            readOnly={!isEditing}
                            placeholder="Ingresá el apellido de tu chofer"
                            type="text"
                            name="last_name"
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue(
                                "last_name",
                                value.toLowerCase().charAt(0).toUpperCase() +
                                  value?.slice(1)
                              );
                            }}
                            errors={errors}
                            value={values.last_name}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        <Form.Group>
                          <label className="text-primary font-weight-bold">
                            Ingresá el CUIT/CUIL de tu chofer
                          </label>
                          <LogInput
                            readOnly={!isEditing}
                            placeholder="Ingresá el cuit del chofer"
                            type="text"
                            name="cuit"
                            disabled={entity?._id ? true : false}
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue("cuit", value);
                            }}
                            errors={errors}
                            value={values.cuit}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </CardLogBootstrapStyled.Body>
                </CardLogBootstrapStyled>
                <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
                  <CardLogBootstrapStyled.Body>
                    <Row>
                      <Col md="4">
                        <label className="text-primary font-weight-bold">
                          Ingresa la fecha de nacimiento de tu chofer
                        </label>
                        <LogDateInput
                          readOnly={!isEditing}
                          placeholder="Ingresá la fecha de nacimiento"
                          name="date_of_birth"
                          onChange={(e) =>
                            setFieldValue(
                              "date_of_birth",
                              e ? new Date(e).toISOString() : null
                            )
                          }
                          onBlur={handleBlur.date_of_birth}
                          errors={errors}
                          value={
                            values?.date_of_birth
                              ? new Date(values?.date_of_birth)
                              : null
                          }
                        />
                      </Col>
                      <Col md="4">
                        <Form.Group>
                          <label className="text-primary font-weight-bold">
                            Ingresá el email de tu chofer
                          </label>
                          <LogInput
                            readOnly={!isEditing}
                            placeholder="Ingresá el email de tu chofer"
                            type="text"
                            name="email"
                            disabled={entity?._id ? true : false}
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue("email", value.toLowerCase());
                            }}
                            errors={errors}
                            value={values.email}
                          />
                        </Form.Group>
                      </Col>
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Tooltip id={`tooltip-left`}>
                            Este Numero Telefónico es el que el Dador tendrá a
                            disposición - servirá para coordinar temas de
                            Logística
                          </Tooltip>
                        }
                      >
                        <Col md="4">
                          <Form.Group>
                            <label className="text-primary font-weight-bold">
                              Teléfono de logistica
                            </label>
                            <LogInputPhone
                              placeholder="Ingresá el Teléfono de logistica"
                              className="mb-2 number-font"
                              name="phone"
                              onChange={(phone) => {
                                if (phone && !phone.startsWith("+")) {
                                  phone = "+" + phone;
                                }
                                setFieldValue("phone", phone);
                              }}
                              onBlur={handleBlur}
                              disabled={!isEditing}
                              errors={errors}
                              value={values.phone}
                            />
                          </Form.Group>
                        </Col>
                      </OverlayTrigger>
                    </Row>
                  </CardLogBootstrapStyled.Body>
                </CardLogBootstrapStyled>
                <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
                  <CardLogBootstrapStyled.Header className="pl-0 pt-4 pr-0 ml-4 mr-4 mb-0">
                    <label className="text-primary font-weight-bold mb-0">
                      Documentos
                    </label>
                  </CardLogBootstrapStyled.Header>
                  <CardLogBootstrapStyled.Body>
                    <Row>
                      <Col md="6" className="mb-2">
                        <LogSelect
                          readOnly={!isEditing}
                          cleanable={false}
                          name="license_type"
                          placeholder="Tipo de licencia"
                          items={[
                            {
                              label: "Licencia cargas peligrosas",
                              value: "dangerous",
                            },
                            {
                              label: "Licencia cargas Generales",
                              value: "general",
                            },
                          ]}
                          onChange={(v) => {
                            setFieldValue("is_danger", v);
                          }}
                          onBlur={handleBlur.is_danger}
                          errors={errors}
                          value={values.is_danger}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <label className="back-primary font-weight-bold">
                          Licencia frente
                        </label>
                        <DropZone
                          readOnly={!isEditing}
                          reset={resetDropZone}
                          image={
                            showLicenseOnePhoto ? entity?.license_one : null
                          }
                          uploadFile={(v) => {
                            setLicenseOnePhoto({
                              file: v,
                              url: v,
                            });
                            setFieldValue("license_one", v?.name);
                          }}
                          errors={errors?.license_one}
                          removeFile={() => {
                            setFieldValue("license_one", "");
                            setLicenseOnePhoto(null);
                            setShowLicenseOnePhoto(null);
                          }}
                          placeholder="Subir frente de licencia"
                        />
                      </Col>
                      <Col md="6" className="mt-2 mt-md-0">
                        <label className="back-primary font-weight-bold">
                          Licencia dorso
                        </label>
                        <DropZone
                          reset={resetDropZone}
                          readOnly={!isEditing}
                          image={
                            showLicenseTwoPhoto ? entity?.license_two : null
                          }
                          uploadFile={(v) => {
                            setLicenseTwoPhoto({
                              file: v,
                              url: v,
                            });
                            setFieldValue("license_two", v?.name);
                          }}
                          errors={errors?.license_two}
                          removeFile={() => {
                            setFieldValue("license_two", "");
                            setLicenseTwoPhoto(null);
                            setShowLicenseTwoPhoto(null);
                          }}
                          placeholder="Subir dorso de licencia"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <LogDatePicker
                          readOnly={!isEditing}
                          cleanable={true}
                          name="license_date"
                          placeholder="Vencimiento Licencia"
                          className="number-font mt-2"
                          onBlur={handleBlur}
                          errors={errors}
                          value={
                            values.license_date
                              ? new Date(values.license_date)
                              : null
                          }
                          onChange={(value) => {
                            setFieldValue("license_date", value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col md="6">
                        <label className="back-primary font-weight-bold">
                          LNH frente
                        </label>
                        <DropZone
                          readOnly={!isEditing}
                          reset={resetDropZone}
                          image={showLintiOnePhoto ? entity?.linti_one : null}
                          uploadFile={(v) => {
                            setLintiOnePhoto({
                              file: v,
                              url: v,
                            });
                            setFieldValue("linti_one", v?.name);
                          }}
                          errors={errors?.linti_one}
                          removeFile={() => {
                            setFieldValue("linti_one", "");
                            setLintiOnePhoto(null);
                            setShowLintiOnePhoto(null);
                          }}
                          placeholder="Subir frente de LNH"
                        />
                      </Col>
                      <Col md="6" className="mt-2 mt-md-0">
                        <label className="back-primary font-weight-bold">
                          LNH dorso
                        </label>
                        <DropZone
                          reset={resetDropZone}
                          readOnly={!isEditing}
                          image={showLintiTwoPhoto ? entity?.linti_two : null}
                          uploadFile={(v) => {
                            setLintiTwoPhoto({
                              file: v,
                              url: v,
                            });
                            setFieldValue("linti_two", v?.name);
                          }}
                          errors={errors?.linti_two}
                          removeFile={() => {
                            setFieldValue("linti_two", "");
                            setLintiTwoPhoto(null);
                            setShowLintiTwoPhoto(null);
                          }}
                          placeholder="Subir dorso de LNH"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <LogDatePicker
                          readOnly={!isEditing}
                          cleanable={true}
                          name="lnh_date"
                          placeholder="Vencimiento Linti"
                          className="number-font mt-2"
                          onBlur={handleBlur}
                          errors={errors}
                          value={
                            values.lnh_date ? new Date(values.lnh_date) : null
                          }
                          onChange={(value) => {
                            setFieldValue("lnh_date", value);
                          }}
                        />
                      </Col>
                    </Row>
                  </CardLogBootstrapStyled.Body>
                </CardLogBootstrapStyled>
              </Col>
              <Row className="w-100 mx-1">
                {entity._id ? (
                  isEditing ? (
                    <>
                      <Col md={6} className=" text-center text-md-left">
                        <StyledLogButton
                          className="mt-4 "
                          color="secondary"
                          size="md"
                          title={"Cancelar"}
                          border="curve"
                          block
                          onClick={() => {
                            resetForm();
                            resetEditing();
                          }}
                        />
                      </Col>
                      <Col md={6} className="text-center text-md-right">
                        <StyledLogButton
                          className="mt-4  "
                          size="md"
                          title={"Guardar cambios"}
                          border="curve"
                          block
                          disabled={isSubmitting}
                          onClick={() => {
                            if (!dirty) {
                              errorToast(
                                "Primero introduzca cambios en el formulario"
                              );
                            } else {
                              handleSubmit();
                              setResetDropZone(false);
                            }
                          }}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col>
                      <StyledLogButton
                        className="mt-4"
                        size="md"
                        title={"Editar"}
                        border="curve"
                        block
                        onClick={(event) => {
                          event.stopPropagation();
                          setResetDropZone(false);
                          setIsEditing(true);
                        }}
                      />
                    </Col>
                  )
                ) : (
                  <Col>
                    <StyledLogButton
                      className="mt-4"
                      size="md"
                      title={`${entity?._id ? "Editar" : "Agregar"}`}
                      border="curve"
                      block
                      /* onClick={handleSubmit} */

                      onClick={() => {
                        if (!isValid) {
                          errorToast("Controle los datos faltantes");
                        } else {
                          if (!dirty) {
                            errorToast("Introduzca datos en el formulario");
                          }
                          handleSubmit();
                        }
                      }}
                    />
                  </Col>
                )}
              </Row>

              <div className="clearfix"></div>
              <ModalQuestion
                title="¿Estás seguro de esta accion?"
                subTitle={`Pasar el chofer "${
                  entity.name + " " + entity.last_name
                }" a estado "${entity.is_active ? "Inactivo" : "Activo"}"`}
                isShow={modalExit}
                handleClose={handleCloseExitModal}
              />
              <ModalInfo
                title={`Tu chofer se guardó exitosamente`}
                subTitle="Podés verlo en tu lista de choferes"
                isShow={successModal}
                handleClose={handleSuccessModal}
              />
            </Row>
          )}
        </Formik>
      </Container>
    </>
  );
}

export default MyDriver;
