import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { getClaims, postClaim } from "../../../../../../../api/modules";
import { errorToast } from "../../../../../../../services/toastService";
import { useEffect } from "react";
import { useState } from "react";
import ChatWindow from "../../../../../../../components/organisms/chatWindow/ChatWindow";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoadingSpinerOff,
  setLoadingSpinerOn,
} from "../../../../../../../redux/actions/app";
import { uploadToCloudinary } from "../../../../../../../utils/helpers";

const Claim = ({ id_trip, id_tripDetail, tripId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authApp.user);
  const [claims, setClaims] = useState({});

  const uploadData = async (document) => {
    const file = await uploadToCloudinary({
      file: document,
      folder: tripId ? `claim/${tripId || id_tripDetail}` : "claim",
    });
    if (file) {
      return file?.secure_url;
    } else {
      errorToast("Por favor intente nuevamente");
    }
  };

  const handleSubmitAPI = async ({
    values,
    resetForm,
    setImage,
    image,
    id_receptor,
  }) => {
    try {
      dispatch(setLoadingSpinerOn());
      let document = "";
      if (values?.document) {
        document = await uploadData(image);
      }
      if (values?.comment || document) {
        const { msg } = await postClaim({
          ...values,
          document,
          id_trip,
          id_tripDetail,
          id_receptor,
        });

        if (msg) {
          errorToast(msg);
        }
      }
      resetForm();
      setImage({});
      getAllClaims();
    } catch (error) {
      console.error("error", error);
      resetForm();
      setImage({});
      getAllClaims();
      errorToast(error?.message + ". Por favor intente nuevamente");
    } finally {
      dispatch(setLoadingSpinerOff());
    }
  };
  const getAllClaims = async () => {
    const { msg, claim, claimDetails } = await getClaims(id_tripDetail);
    if (!msg) {
      setClaims({ claim, claimDetails });
    } else {
      errorToast(msg);
    }
  };
  useEffect(() => {
    getAllClaims();
  }, []);

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md={10}>
          <ChatWindow
            title={"Mensajes de Reclamo"}
            handleSubmitAPI={handleSubmitAPI}
            data={claims.claimDetails}
            roleSent={user?.role_id?.name}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Claim;
