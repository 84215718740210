import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import styled from "styled-components";
import LogButton from "../../../../../../../../components/atoms/LogButton";
import { postClaim, putCloseClaim } from "../../../../../../../../api/modules";
import { errorToast } from "../../../../../../../../services/toastService";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { getClaimsAdmin } from "../../../../../../../../api/modules";
import {
  ADMIN,
  DADOR_DE_CARGA,
} from "../../../../../../../../constants/UserType";
import ChatWindow from "../../../../../../../../components/organisms/chatWindow/ChatWindow";
import {
  setLoadingSpinerOff,
  setLoadingSpinerOn,
} from "../../../../../../../../redux/actions/app";
import { uploadToCloudinary } from "../../../../../../../../utils/helpers";

const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  min-height: 70vh;
`;

const ClaimAdminDador = ({ id_trip, id_tripDetail, tripId }) => {
  const dispatch = useDispatch();
  const [claims, setClaims] = useState({});
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const inToView = useRef(null);
  const scrollToView = () => {
    inToView.current?.scrollIntoView({ behavior: "smooth" });
  };

  const uploadData = async (document) => {
    const file = await uploadToCloudinary({
      file: document,
      folder: tripId ? `claim/${tripId || id_tripDetail}` : "claim",
    });
    if (file) {
      return file?.secure_url;
    } else {
      errorToast("Por favor intente nuevamente");
    }
  };

  const handleSubmitAPI = async ({
    values,
    resetForm,
    setImage,
    image,
    id_receptor,
  }) => {
    try {
      dispatch(setLoadingSpinerOn());
      let document = "";
      if (values?.document) {
        document = await uploadData(image);
      }
      if (values?.comment || document) {
        const { msg } = await postClaim({
          ...values,
          document,
          id_trip,
          id_tripDetail,
          id_receptor,
        });

        if (msg) {
          errorToast(msg);
        }
      }
      resetForm();
      setImage({});
      getAllClaims();
    } catch (error) {
      console.error("error", error);
      resetForm();
      setImage({});
      getAllClaims();
      errorToast(error?.message + ". Por favor intente nuevamente");
    } finally {
      dispatch(setLoadingSpinerOff());
    }
  };
  const getAllClaims = async () => {
    const { msg, claim, claimCreator, claimed } = await getClaimsAdmin(
      id_tripDetail
    );
    if (!msg) {
      setClaims({
        claim,
        claimCreator,
        claimed,
      });
    } else {
      errorToast(msg);
    }
  };

  const handleCloseClaim = async () => {
    const { msg } = await putCloseClaim({ claim_id: claims.claim._id });
    getAllClaims();
    if (msg) {
      errorToast(msg);
    }
  };

  useEffect(() => {
    getAllClaims();
  }, []);

  useEffect(() => {
    scrollToView();
  }, [claims]);

  return (
    <Container className="py-4">
      {claims.claim ? (
        <>
          {" "}
          <Row className="justify-content-center">
            <Col md={6} className="mb-2">
              <ChatWindow
                title={"Reclamante"}
                subTitle={
                  <Row className="d-flex justify-content-between align-text-bottom m-0">
                    <small>
                      <p className="text-primary align-text-bottom text-capitalize mb-1 font-weight-bold">
                        {claims.claim.id_creator.name +
                          " " +
                          claims.claim.id_creator.last_name}
                      </p>
                    </small>
                    <small>
                      <p className="text-primary text-center mb-1 font-weight-bold">
                        {claims.claim.id_creator.name === DADOR_DE_CARGA
                          ? "Dador"
                          : "Tomador"}
                      </p>
                    </small>
                  </Row>
                }
                handleSubmitAPI={handleSubmitAPI}
                data={claims.claimCreator}
                roleSent={ADMIN}
                active={claims.claim}
                id_receptor={claims.claim.id_creator}
              />
            </Col>
            <Col md={6}>
              <ChatWindow
                title={"Reclamado"}
                subTitle={
                  <Row className="d-flex justify-content-between align-text-bottom m-0">
                    <small>
                      <p className="text-primary align-text-bottom text-capitalize mb-1 font-weight-bold">
                        {claims.claim.id_claimed.name +
                          " " +
                          claims.claim.id_claimed.last_name}
                      </p>
                    </small>
                    <small>
                      <p className="text-primary text-center mb-1 font-weight-bold">
                        {claims.claim.id_creator.name === DADOR_DE_CARGA
                          ? "Tomador"
                          : "Dador"}
                      </p>
                    </small>
                  </Row>
                }
                handleSubmitAPI={handleSubmitAPI}
                data={claims.claimed}
                roleSent={ADMIN}
                active={claims.claim}
                id_receptor={claims.claim.id_claimed}
              />
            </Col>
          </Row>
          <Row className="justify-content-center mt-1" ref={inToView}>
            {claims.claim?.status ? (
              <LogButton
                size={20}
                title={"Cerrar Reclamo"}
                onClick={handleCloseClaim}
              ></LogButton>
            ) : (
              <LogButton size={20} title={"Reclamo Cerrado"}></LogButton>
            )}
          </Row>{" "}
        </>
      ) : !spinner ? (
        <DivEmpty>
          <h2 className="font-weight-bold h3 mt-3">No hay reclamo inciado</h2>
        </DivEmpty>
      ) : (
        <DivEmpty></DivEmpty>
      )}
    </Container>
  );
};

export default ClaimAdminDador;
